// import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react"
import {Link} from "gatsby"
import {Col, Row} from 'react-bootstrap';
import {rhythm, scale} from "../utils/typography"


function renderFooter() {
    return <>
        © {new Date().getFullYear()}, Команда проекта <a href="https://spotswat.com">ГДЕ Угроза?</a>
        <footer>
            <Row>
                <Col sm="12">
                    <p>
                    </p>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                {/*style="width:100%"*/}
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td>
                            <Col sm="3">
                                <h4>
                                    Ресурсы
                                </h4>

                                <p>
                                    <a className="text-muted" href="https://spotswat.com"
                                       target="_blank">Сайт проекта</a>
                                </p>

                                <p>
                                    <a href="https://storage.googleapis.com/web_files_static/spotswat.apk"
                                       target="_blank">Android-приложение</a>
                                </p>
                                <p>
                                    <a href="https://bessrochka.com/" target="_blank">Бессрочка: децентрализованный
                                        гражданский мирный протест</a>
                                </p>
                            </Col>

                        </td>
                        <td>

                            <Col sm="3">
                                <h4>
                                    Телеграм
                                </h4>

                                <p>
                                    <a href="tg://resolve?domain=geo_track_current"
                                       target="_blank">Новостная Лента</a>
                                </p>

                                <p>
                                    <a href="tg://resolve?domain=geo_scout_bot" target="_blank">Бот</a>
                                </p>

                                <p>
                                    <a href="tg://resolve?domain=geo_track_moscow"
                                       target="_blank">Московский чат</a>
                                </p>

                                <p>
                                    <a href="tg://resolve?domain=geo_track_spb"
                                       target="_blank">Петербургский чат</a>
                                </p>
                            </Col>

                        </td>
                        <td>
                            <Col sm="6">
                                <p>
                                    Использование материалов без указания источника запрещено.
                                    По всем вопросам пишите в бот обратной связи: <a
                                    href="tg://resolve?domain=geo_scout_feedback_bot"
                                    target="_blank">@geo_scout_feedback_bot</a>.
                                </p>
                                <p>
                                    <a href="/terms/"
                                       target="_blank">Пользовательское соглашение</a>
                                </p>

                            </Col>

                        </td>
                    </tr>

                </table>
            </Row>

        </footer>
    </>;
}

const Layout = ({location, title, children}) => {
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
        header = (
            <h1
                style={{
                    ...scale(1.5),
                    marginBottom: rhythm(1.5),
                    marginTop: 0,
                }}
            >
                <Link
                    style={{
                        boxShadow: `none`,
                        color: `inherit`,
                    }}
                    to={`/`}
                >
                    {title}
                </Link>
            </h1>
        )
    } else {
        header = (
            <h3
                style={{
                    fontFamily: `Montserrat, sans-serif`,
                    marginTop: 0,
                }}
            >
                <Link
                    style={{
                        boxShadow: `none`,
                        color: `inherit`,
                    }}
                    to={`/`}
                >
                    {title}
                </Link>
            </h3>
        )
    }
    return (
        <div
            style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: rhythm(24),
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
        >
            <header>{header}</header>
            <main>{children}</main>

            {renderFooter()}
        </div>
    )
}

export default Layout
